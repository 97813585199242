<template>
    <v-menu :close-on-content-click="false" v-model="menu" max-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field :prefix="prefix" :persistent-hint="persistentHint" :flat="flat" :dense="dense" :disabled="disabled" :clearable="clearable" @click:clear="updateValue(null)" :hide-details="hideDetails" v-on="on" :outlined="outlined" :solo-inverted="soloInverted" :label="label" v-model="dataInternaPt" readonly />
        </template>
        <v-date-picker show-current @input="updateValue" v-model="dataInternaEn" />
    </v-menu>
</template>

<script>
export default {
    name: "DatePickerDialogInput",
    props : ["label", "value", "flat", "hideDetails", "outlined", "soloInverted", "dense", "prefix", "persistentHint", "clearable", "disabled"],
    data: () => ({
        menu : false,
        dataInternaEn : null,
        dataInternaPt : null
    }),
    methods : {
        updateValue(v) {
            if (v == '') {
                v = null
            }
            this.menu = false
            this.dataInternaPt = this.dataPt(v)
            this.$emit('input', v)
        }
    },
    watch : {
        'value' : function (v) {
            this.dataInternaEn = v
            this.dataInternaPt = this.dataPt(v)
        },
    },
    created() {
        this.dataInternaEn = this.value
        this.dataInternaPt = this.dataPt(this.value)
    }
}
</script>

<style scoped>

</style>

